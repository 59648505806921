@font-face {
    font-family: 'futura';
    src: url('./font/FuturaLT.ttf');
}

.page-title {
  background-color: black;
  display: flex;
  padding-left: 80px;
  font-size: calc(64px + 2vmin);
  font-family: futura;
  color: #c1c1c1;
}

.post-time {
  background-color: black;
  display: flex;
  padding-left: 80px;
  font-size: calc(32px + 2vmin);
  font-family: futura;
  color: #c1c1c1;
}

.post-content {
  background-color: black;
  display: flex;
  padding-left: 100px;
  font-size: calc(24px + 2vmin);
  font-family: futura;
  color: #ffffff;
}
